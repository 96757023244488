/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import type { GridItemProps, GridProps } from '@pt-group-fe/styled-system/jsx';
import { Grid, GridItem } from '@pt-group-fe/styled-system/jsx';
import { layoutBox, layoutGrid } from '@pt-group-fe/styled-system/recipes';

import { Cms_GridVariant } from '@lib/services/cms';

export interface LayoutBoxProps extends GridItemProps {}

/**
 * no columns ( 1 - 12 ), no grid
 */
export const Layout = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ children, className }, ref) => (
    <div ref={ref} className={cx('layout', className)}>
      {children}
    </div>
  )
);
Layout.displayName = 'Layout';

/**
 * grid, 12 cols, gap 32px
 */
export interface LayoutGridProps extends GridProps {}

export const LayoutGrid = React.forwardRef<HTMLDivElement, LayoutGridProps>((props, ref) => {
  const { children, className, columns = 12, ...rest } = props;

  return (
    <Grid
      ref={ref}
      columns={columns}
      rowGap={8}
      columnGap={{ md: 8 }}
      className={cx(layoutGrid(), className)}
      {...rest}
    >
      {children}
    </Grid>
  );
});

LayoutGrid.displayName = 'LayoutGrid';

/**
 * no columns ( 1 - 12 )
 */
export const LayoutBoxFull = React.forwardRef<HTMLDivElement, LayoutBoxProps>((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <GridItem
      ref={ref}
      colStart={1}
      colEnd={13}
      className={cx(layoutBox(), 'layout-box-full', className)}
      {...rest}
    >
      {children}
    </GridItem>
  );
});
LayoutBoxFull.displayName = 'LayoutBoxFull';

/**
 * smaller no columns ( 2 - 11 )
 */
export const LayoutBoxSmaller = React.forwardRef<HTMLDivElement, LayoutBoxProps>((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <GridItem
      ref={ref}
      colStart={{ base: 1, lg: 2 }}
      colEnd={{ base: 13, lg: 12 }}
      className={cx(layoutBox(), 'layout-box-smaller', className)}
      {...rest}
    >
      {children}
    </GridItem>
  );
});
LayoutBoxSmaller.displayName = 'LayoutBoxSmaller';

/**
 * tighter no columns ( 3 - 10 )
 */
export const LayoutBoxTight = React.forwardRef<HTMLDivElement, LayoutBoxProps>((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <GridItem
      ref={ref}
      colStart={{ base: 1, lg: 3 }}
      colEnd={{ base: 13, lg: 11 }}
      className={cx(layoutBox(), 'layout-box-tight', className)}
      {...rest}
    >
      {children}
    </GridItem>
  );
});
LayoutBoxTight.displayName = 'LayoutBoxTight';

/**
 * smaller 2 cols (2-5, [6-11])
 */
export const LayoutBoxSmaller2ColsLeft = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  (props, ref) => {
    const { className, children, ...rest } = props;

    return (
      <GridItem
        ref={ref}
        colStart={{ base: 1, md: 2 }}
        colEnd={{ base: 13, md: 6 }}
        className={cx(layoutBox(), 'layout-box-smaller-2cols-left', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBoxSmaller2ColsLeft.displayName = 'LayoutBoxSmaller2ColsLeft';

/**
 * smaller 2 cols (2-5, [6-11])
 */
export const LayoutBoxSmaller2ColsRight = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  (props, ref) => {
    const { className, children, ...rest } = props;

    return (
      <GridItem
        ref={ref}
        colStart={{ base: 1, md: 6 }}
        colEnd={{ base: 13, md: 12 }}
        className={cx(layoutBox(), 'layout-box-smaller-2cols-right', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBoxSmaller2ColsRight.displayName = 'LayoutBoxSmaller2ColsRight';

/**
 * 2 cols 50/50
 */
export const LayoutBox5050 = React.forwardRef<HTMLDivElement, LayoutBoxProps>((props, ref) => {
  const { className, children, ...rest } = props;

  return (
    <GridItem
      ref={ref}
      colSpan={{ base: 12, md: 6 }}
      className={cx(layoutBox(), 'layout-box-50-50', className)}
      {...rest}
    >
      {children}
    </GridItem>
  );
});
LayoutBox5050.displayName = 'LayoutBox5050';

/**
 * tiles grid item (news , blog, etc)
 */
export const LayoutGridTile = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <GridItem
        ref={ref}
        colSpan={{ base: 12, md: 6, lg: 4, xl: 3 }}
        className={cx(layoutBox(), 'layout-grid-tile', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutGridTile.displayName = 'LayoutGridTile';

/**
 * tiles grid item (news , blog, etc)
 */
export const LayoutBox4Cols = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <GridItem
        ref={ref}
        colSpan={{ base: 12, md: 6, lg: 4, xl: 3 }}
        className={cx(layoutBox(), 'layout-grid-tile', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBox4Cols.displayName = 'LayoutBox4Cols';

/**
 * tiles grid item (news , blog, etc)
 */
export const LayoutBox3Cols = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <GridItem
        ref={ref}
        colSpan={{ base: 12, md: 6, lg: 4 }}
        className={cx(layoutBox(), 'layout-grid-tile', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBox3Cols.displayName = 'LayoutGrid3Cols';

/**
 * tiles grid item (news , blog, etc)
 */
export const LayoutBox2Cols = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <GridItem
        ref={ref}
        colSpan={{ base: 12, md: 6 }}
        className={cx(layoutBox(), 'layout-grid-tile', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBox2Cols.displayName = 'LayoutBox2Cols';

/**
 * tiles grid item (news , blog, etc)
 */
export const LayoutBox1Cols = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <GridItem
        ref={ref}
        colSpan={12}
        className={cx(layoutBox(), 'layout-grid-tile', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBox1Cols.displayName = 'LayoutBox1Cols';

/**
 *  grid item 4 / 8 left
 */
export const LayoutBox_1_2_Left = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <GridItem
        ref={ref}
        colStart={1}
        colEnd={{ base: 13, md: 5 }}
        className={cx(layoutBox(), 'layout-box-1-2-left', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBox_1_2_Left.displayName = 'LayoutBox_1_2_Left';

/**
 *  grid item 4 / 8 right
 */
export const LayoutBox_1_2_Right = React.forwardRef<HTMLDivElement, LayoutBoxProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <GridItem
        ref={ref}
        colStart={{ base: 1, md: 5 }}
        colEnd={13}
        className={cx(layoutBox(), 'layout-box-1-2-right', className)}
        {...rest}
      >
        {children}
      </GridItem>
    );
  }
);
LayoutBox_1_2_Right.displayName = 'LayoutBox_1_2_Right';

/**
 * dynamic grid from layout
 * export type Cms_GridVariant = 'cols_1' | 'cols_2' | 'cols_3' | 'cols_4';
 */
export const CmsDynamicGridItem = React.forwardRef<
  HTMLDivElement,
  LayoutBoxProps & { variant: Cms_GridVariant }
>(({ variant, ...rest }, ref) => {
  let content: React.ReactNode = null;

  switch (variant) {
    case 'cols_1':
      content = <LayoutBox1Cols ref={ref} {...rest} />;
      break;

    case 'cols_2':
      content = <LayoutBox2Cols ref={ref} {...rest} />;
      break;

    case 'cols_3':
      content = <LayoutBox3Cols ref={ref} {...rest} />;
      break;

    case 'cols_4':
      content = <LayoutBox4Cols ref={ref} {...rest} />;
      break;

    default:
      break;
  }

  return content;
});
CmsDynamicGridItem.displayName = 'CmsDynamicGridItem';
