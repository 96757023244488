import React, { CSSProperties, PropsWithChildren } from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { TextVariantProps, text } from '@pt-group-fe/styled-system/recipes';

import { HtmlTagName } from '@lib/types';

import { DynamicTag } from '../dynamicTag';

interface TextProps extends PropsWithChildren, TextVariantProps {
  as?: HtmlTagName;
  className?: string;
  style?: CSSProperties;
}

const Text: React.FC<TextProps> = props => {
  const { className, as, variant = 'block', children, leadingNormal, style } = props;
  let _as = as;

  if (!as) {
    switch (variant) {
      case 'block':
        _as = 'p';
        break;

      case 'block-sm':
        _as = 'p';
        break;

      case 'teaser':
        _as = 'p';
        break;

      case 'h1':
        _as = 'h1';
        break;

      case 'h2':
        _as = 'h2';
        break;

      case 'h3':
        _as = 'h3';
        break;

      case 'h4':
        _as = 'h4';
        break;

      case 'h5':
        _as = 'h5';
        break;

      case 'h6':
        _as = 'h6';
        break;

      default:
        _as = 'div';
    }
  }

  let formattedChildren = children;
  if (
    typeof children === 'string' &&
    (children.search(/\n/gi) !== -1 || children.search(/&shy;/gi) !== -1)
  ) {
    formattedChildren = (
      <span
        dangerouslySetInnerHTML={{
          __html: children.replace(/\n/gi, '<br/>') /* .replace(/&shy;/gi, '<wbr/>') */
        }}
      />
    );
  }

  const classes = [`text-${variant}`];
  if ((variant && (variant as string).startsWith('title')) || (variant as string).startsWith('h')) {
    classes.push('text-title');
  }
  if (variant && (variant as string).startsWith('block')) {
    classes.push('text-paragraph');
  }

  return (
    <DynamicTag
      style={style}
      tagName={_as}
      className={cx(text({ variant, leadingNormal }), classes.join(' '), className)}
    >
      {formattedChildren}
    </DynamicTag>
  );
};

const TEXT_VARIANTS: TextVariantProps['variant'] = [
  'block',
  'block-sm',
  'block-xs',
  'teaser',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6'
];

export { Text, TEXT_VARIANTS };
export type { TextProps };
