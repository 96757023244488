import React from 'react';

import { cx } from '@pt-group-fe/styled-system/css';
import { IconVariantProps, icon as iconx } from '@pt-group-fe/styled-system/recipes';

type IconType =
  | 'arrow-right'
  | 'play'
  | 'sort'
  | 'moveLeft'
  | 'moveRight'
  | 'shopping-cart'
  | 'high-five'
  | 'mail-text'
  | 'stop'
  | 'pause'
  | 'search'
  | 'linkedin'
  | 'check-circle'
  | 'angles-collapse'
  | 'angles-expand'
  | 'share'
  | 'check'
  | 'angle-down-circle'
  | 'angle-up-circle'
  | 'download'
  | 'envelope'
  | 'exclamation-circle'
  | 'triangle-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'close'
  | 'phone'
  | 'pin-location'
  | 'flag-exclamation'
  | 'factory-building'
  | 'zoom-in'
  | 'zoom-out'
  | 'expand'
  | 'cookie'
  | 'question-circle'
  | 'dashboard'
  | 'add'
  | 'cog'
  | 'analytic-board-bars'
  | 'subscription'
  | 'paper-write'
  | 'house'
  | 'plus-circle'
  | 'show-hat-magician'
  | 'list-to-do'
  | 'messages-bubble-square-information'
  | 'navigation-menu-vertical'
  | 'remove-circle'
  | 'link'
  | 'user'
  | 'menu-burger'
  | 'login';

const ICONS: IconType[] = [
  'arrow-right',
  'play',
  'sort',
  'moveLeft',
  'moveRight',
  'shopping-cart',
  'high-five',
  'mail-text',
  'stop',
  'pause',
  'search',
  'linkedin',
  'check-circle',
  'angles-collapse',
  'angles-expand',
  'share',
  'check',
  'angle-down-circle',
  'angle-up-circle',
  'download',
  'envelope',
  'exclamation-circle',
  'triangle-down',
  'chevron-left',
  'chevron-right',
  'close',
  'phone',
  'pin-location',
  'flag-exclamation',
  'factory-building',
  'zoom-in',
  'zoom-out',
  'expand',
  'cookie',
  'question-circle',
  'dashboard',
  'add',
  'cog',
  'analytic-board-bars',
  'subscription',
  'paper-write',
  'house',
  'plus-circle',
  'show-hat-magician',
  'list-to-do',
  'navigation-menu-vertical',
  'remove-circle',
  'link',
  'user',
  'menu-burger',
  'login'
];

interface IconProps extends Omit<React.HTMLAttributes<SVGElement>, 'color'>, IconVariantProps {
  i: IconType;
  className?: string;
}

const Icon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { i, className, size = 'default', ...restProps } = props;
  let icon = null;

  switch (i) {
    case 'arrow-right':
      icon = (
        <>
          <g>
            <path d="M22.7747 12H1.22559" />
            <path d="M11.2881 22.5416L22.446 12.7196C22.5479 12.6297 22.6296 12.5191 22.6855 12.3952C22.7415 12.2712 22.7704 12.1368 22.7704 12.0009C22.7704 11.8649 22.7415 11.7305 22.6855 11.6066C22.6296 11.4827 22.5479 11.3721 22.446 11.2821L11.2881 1.45825" />
          </g>
        </>
      );
      break;

    case 'moveLeft':
      icon = (
        <>
          <path
            d="M21.9836875 17.790212500000003V5.2097875c0 -1.7369791666666667 -1.4080791666666668 -3.1451541666666665 -3.1450583333333335 -3.1451541666666665h-2.0967375c-1.7370750000000001 0 -3.1451541666666665 1.4081750000000002 -3.1451541666666665 3.1451541666666665v12.580425c0 1.7370750000000001 1.4080791666666668 3.1451541666666665 3.1451541666666665 3.1451541666666665h2.0967375c1.7369791666666667 0 3.1450583333333335 -1.4080791666666668 3.1450583333333335 -3.1451541666666665Z"
            stroke="currentColor"
            strokeWidth="1"
          />
          <path
            d="M7.306525000000001 2.064633333333333H5.2097875c-2.3160041666666666 0 -4.193475 1.8774708333333334 -4.193475 4.193475v10.483783333333335c0 2.3159083333333332 1.8774708333333334 4.193475 4.193475 4.193475h2.0967375"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M13.596737500000001 11.5H5.2097875m0 0 3.1450583333333335 -3.1451541666666665M5.2097875 11.5l3.1450583333333335 3.1451541666666665"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </>
      );
      break;

    case 'moveRight':
      icon = (
        <>
          <path
            d="M1.0163125 17.790212500000003V5.2097875c0 -1.7369791666666667 1.4080791666666668 -3.1451541666666665 3.1450583333333335 -3.1451541666666665h2.0967375c1.7370750000000001 0 3.1451541666666665 1.4081750000000002 3.1451541666666665 3.1451541666666665v12.580425c0 1.7370750000000001 -1.4080791666666668 3.1451541666666665 -3.1451541666666665 3.1451541666666665H4.161370833333334c-1.7369791666666667 0 -3.1450583333333335 -1.4080791666666668 -3.1450583333333335 -3.1451541666666665Z"
            stroke="currentColor"
            strokeWidth="1"
          />
          <path
            d="M15.693475000000003 2.064633333333333h2.0967375c2.3160041666666666 0 4.193475 1.8774708333333334 4.193475 4.193475v10.483783333333335c0 2.3159083333333332 -1.8774708333333334 4.193475 -4.193475 4.193475h-2.0967375"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M9.4032625 11.5h8.38695m0 0 -3.1450583333333335 -3.1451541666666665M17.790212500000003 11.5l-3.1450583333333335 3.1451541666666665"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </>
      );
      break;

    case 'shopping-cart':
      icon = (
        <>
          <path
            d="M23.5 2.5h-2.558a0.5 0.5 0 0 0 -0.5 0.439l-0.332 2.684a1 1 0 0 1 -0.992 0.877H2A1.5 1.5 0 0 0 0.569 8.447l1.562 5A1.5 1.5 0 0 0 3.563 14.5H18a1 1 0 0 1 0 2H4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="m19.501 8.5 -0.442 3.978"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M4.501 20a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M15.501 20a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </>
      );
      break;

    case 'mail-text':
      icon = (
        <>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0.786999 12.3 9.781 18.86c0.14115 0.1029 0.3113 0.1583 0.486 0.1583 0.1747 0 0.3448 -0.0554 0.486 -0.1583l3.747 -2.726"
            strokeWidth="1"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m7.399 17.118 -6.294 6.294"
            strokeWidth="1"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m13.138 17.121 5.691 5.691"
            strokeWidth="1"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.27 0.500001h-8.482c-0.4053 -0.000394 -0.8067 0.079078 -1.1813 0.233875 -0.3745 0.154797 -0.71494 0.381884 -1.00171 0.668284 -0.28678 0.2864 -0.5143 0.62651 -0.66959 1.00088 -0.15528 0.37437 -0.23527 0.77567 -0.2354 1.18096v4.093c0.00026 0.81802 0.32541 1.60245 0.90393 2.18078 0.57847 0.57832 1.36307 0.90322 2.18107 0.90322H14v2.855l3.855 -2.855h2.415c0.8179 0 1.6024 -0.3249 2.1807 -0.90328 0.5784 -0.57836 0.9033 -1.36279 0.9033 -2.18072V3.584c0 -0.81793 -0.3249 -1.60235 -0.9033 -2.18072C21.8724 0.824922 21.0879 0.500001 20.27 0.500001v0Z"
            strokeWidth="1"
          />
          <path
            d="M12.173 6.02899c-0.2071 0 -0.375 -0.16789 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
            strokeWidth="1"
          />
          <path
            d="M12.173 6.02899c0.2071 0 0.375 -0.16789 0.375 -0.375s-0.1679 -0.375 -0.375 -0.375"
            strokeWidth="1"
          />
          <path
            d="M16.029 6.02899c-0.2071 0 -0.375 -0.16789 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
            strokeWidth="1"
          />
          <path
            d="M16.029 6.02899c0.2071 0 0.375 -0.16789 0.375 -0.375s-0.1679 -0.375 -0.375 -0.375"
            strokeWidth="1"
          />
          <path
            d="M19.885 6.02899c-0.2071 0 -0.375 -0.16789 -0.375 -0.375s0.1679 -0.375 0.375 -0.375"
            strokeWidth="1"
          />
          <path
            d="M19.885 6.02899c0.2071 0 0.375 -0.16789 0.375 -0.375s-0.1679 -0.375 -0.375 -0.375"
            strokeWidth="1"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.829 14.149v8.525c0 0.1087 -0.0215 0.2164 -0.0632 0.3168 -0.0417 0.1004 -0.1028 0.1916 -0.1798 0.2684 -0.077 0.0767 -0.1684 0.1375 -0.269 0.1788 -0.1005 0.0413 -0.2083 0.0624 -0.317 0.062H1.472c-0.21907 0 -0.42916 -0.087 -0.58407 -0.2419 -0.154905 -0.1549 -0.241929 -0.365 -0.241929 -0.5841v-9.918c-0.000132 -0.1085 0.021136 -0.2161 0.062588 -0.3164 0.041451 -0.1003 0.102273 -0.1915 0.178987 -0.2683 0.076715 -0.0768 0.167814 -0.1377 0.268094 -0.1793 0.10028 -0.0416 0.20777 -0.063 0.31633 -0.063H7.46"
            strokeWidth="1"
          />
        </>
      );
      break;

    case 'play':
      icon = (
        <path d="M1.57129 2.52984C1.57138 2.36655 1.61319 2.20599 1.69275 2.0634C1.77232 1.9208 1.887 1.8009 2.02592 1.71507C2.16483 1.62925 2.32338 1.58034 2.4865 1.57299C2.64963 1.56565 2.81193 1.6001 2.958 1.67309L21.8985 11.1433C22.0575 11.223 22.1911 11.3454 22.2845 11.4967C22.378 11.648 22.4274 11.8223 22.4274 12.0001C22.4274 12.1779 22.378 12.3522 22.2845 12.5035C22.1911 12.6548 22.0575 12.7772 21.8985 12.8568L2.958 22.3271C2.81193 22.4001 2.64963 22.4345 2.4865 22.4272C2.32338 22.4198 2.16483 22.3709 2.02592 22.2851C1.887 22.1993 1.77232 22.0794 1.69275 21.9368C1.61319 21.7942 1.57138 21.6336 1.57129 21.4703V2.52984Z" />
      );
      break;

    case 'sort':
      icon = (
        <>
          <path d="m17.25 17.25 -5.75 5.75 -5.75 -5.75z" />
          <path d="m5.75 5.75 5.75 -5.75 5.75 5.75z" />
        </>
      );
      break;

    case 'high-five':
      icon = (
        <>
          <path d="M10.679 11.1 11.7 6.759a1.5 1.5 0 0 0 -1.114 -1.805 1.842 1.842 0 0 0 -2.214 1.368L6.2 15.506l-1.356 1.228" />
          <path d="m9.02 14.457 0.614 -2.567a1.006 1.006 0 0 1 1.212 -0.747 1.5 1.5 0 0 1 1.113 1.8l-0.916 3.85a4 4 0 0 1 -0.851 1.673L8.61 20.5" />
          <path d="m0.659 19.68 3.561 -3.57 5.022 5.022 -2.292 2.3" />
          <path d="M13.321 11.1 12.3 6.759a1.5 1.5 0 0 1 1.114 -1.805 1.842 1.842 0 0 1 2.217 1.368l2.169 9.184 1.352 1.228" />
          <path d="m14.98 14.457 -0.614 -2.567a1.006 1.006 0 0 0 -1.212 -0.747 1.5 1.5 0 0 0 -1.113 1.8l0.916 3.85a4 4 0 0 0 0.851 1.673L15.39 20.5" />
          <path d="m23.341 19.68 -3.561 -3.57 -5.022 5.022 2.292 2.3" />
          <path d="m12 0.568 0 2" />
          <path d="m20.742 4.223 -1.414 1.414" />
          <path d="m3.234 4.165 1.414 1.414" />
        </>
      );
      break;

    case 'stop':
      icon = (
        <>
          <path
            d="M3.354166666666667 2.3958333333333335h16.291666666666668s0.9583333333333334 0 0.9583333333333334 0.9583333333333334v16.291666666666668s0 0.9583333333333334 -0.9583333333333334 0.9583333333333334h-16.291666666666668s-0.9583333333333334 0 -0.9583333333333334 -0.9583333333333334v-16.291666666666668s0 -0.9583333333333334 0.9583333333333334 -0.9583333333333334"
            fill="none"
          />
        </>
      );
      break;

    case 'pause':
      icon = (
        <>
          <path
            d="M4.791666666666667 2.3958333333333335h3.8333333333333335s0.9583333333333334 0 0.9583333333333334 0.9583333333333334v16.291666666666668s0 0.9583333333333334 -0.9583333333333334 0.9583333333333334H4.791666666666667s-0.9583333333333334 0 -0.9583333333333334 -0.9583333333333334v-16.291666666666668s0 -0.9583333333333334 0.9583333333333334 -0.9583333333333334"
            fill="none"
          />
          <path
            d="M14.375 2.3958333333333335h3.8333333333333335s0.9583333333333334 0 0.9583333333333334 0.9583333333333334v16.291666666666668s0 0.9583333333333334 -0.9583333333333334 0.9583333333333334h-3.8333333333333335s-0.9583333333333334 0 -0.9583333333333334 -0.9583333333333334v-16.291666666666668s0 -0.9583333333333334 0.9583333333333334 -0.9583333333333334"
            fill="none"
          />
        </>
      );
      break;

    case 'search':
      icon = (
        <>
          <g>
            <path d="M0.979492 10.5626C0.979492 13.1042 1.98916 15.5418 3.78639 17.339C5.58361 19.1362 8.02117 20.1459 10.5628 20.1459C13.1045 20.1459 15.542 19.1362 17.3393 17.339C19.1365 15.5418 20.1462 13.1042 20.1462 10.5626C20.1462 8.02092 19.1365 5.58336 17.3393 3.78614C15.542 1.98892 13.1045 0.979248 10.5628 0.979248C8.02117 0.979248 5.58361 1.98892 3.78639 3.78614C1.98916 5.58336 0.979492 8.02092 0.979492 10.5626Z" />
            <path d="M23.0208 23.0208L17.3389 17.3389" />
          </g>
        </>
      );
      break;

    case 'linkedin':
      icon = (
        <>
          <path d="M6.72917 22.0624H1.9375V9.60409H6.72917V22.0624ZM15.3542 13.4374C14.8458 13.4374 14.3583 13.6394 13.9989 13.9988C13.6394 14.3582 13.4375 14.8458 13.4375 15.3541V22.0624H8.64583V9.60409H13.4375V11.0272C14.5073 10.1205 15.8591 9.61403 17.2613 9.5945C20.0998 9.5945 22.0625 11.7028 22.0625 15.6847V22.0624H17.2708V15.3541C17.2708 15.1024 17.2213 14.8531 17.1249 14.6206C17.0286 14.3881 16.8874 14.1768 16.7095 13.9988C16.5315 13.8208 16.3202 13.6796 16.0876 13.5833C15.8551 13.487 15.6059 13.4374 15.3542 13.4374ZM6.72917 5.29159C6.72917 5.76544 6.58865 6.22865 6.3254 6.62264C6.06214 7.01663 5.68796 7.32371 5.25018 7.50505C4.8124 7.68638 4.33068 7.73383 3.86593 7.64138C3.40118 7.54894 2.97429 7.32076 2.63922 6.9857C2.30416 6.65063 2.07598 6.22374 1.98354 5.75899C1.89109 5.29424 1.93854 4.81252 2.11987 4.37474C2.30121 3.93696 2.60829 3.56278 3.00228 3.29952C3.39627 3.03627 3.85948 2.89575 4.33333 2.89575C4.64796 2.89575 4.9595 2.95772 5.25018 3.07812C5.54086 3.19853 5.80497 3.375 6.02744 3.59748C6.24992 3.81995 6.42639 4.08406 6.5468 4.37474C6.6672 4.66542 6.72917 4.97696 6.72917 5.29159Z" />
        </>
      );
      break;

    case 'check-circle':
      icon = (
        <>
          <g>
            <path d="M17.75 7.2085L11.6358 15.5077C11.5589 15.6124 11.4614 15.7004 11.3494 15.7663C11.2374 15.8322 11.1131 15.8746 10.9842 15.891C10.857 15.9072 10.7279 15.8978 10.6044 15.8632C10.4809 15.8287 10.3657 15.7697 10.2654 15.6897L6.25 12.4793" />
            <path d="M0.979492 12.0001C0.979492 14.923 2.14061 17.7262 4.20742 19.793C6.27423 21.8598 9.07742 23.0209 12.0003 23.0209C14.9232 23.0209 17.7264 21.8598 19.7932 19.793C21.86 17.7262 23.0212 14.923 23.0212 12.0001C23.0212 9.07717 21.86 6.27398 19.7932 4.20718C17.7264 2.14037 14.9232 0.979248 12.0003 0.979248C9.07742 0.979248 6.27423 2.14037 4.20742 4.20718C2.14061 6.27398 0.979492 9.07717 0.979492 12.0001Z" />
          </g>
        </>
      );
      break;

    case 'close':
      icon = (
        <>
          <g>
            <path d="M0.979492 0.978271L23.0212 23.0199" />
            <path d="M23.0212 0.978271L0.979492 23.0199" />
          </g>
        </>
      );
      break;

    case 'angles-collapse':
      icon = (
        <>
          <path d="M20.1462 0.978271L12.0003 9.1241L3.85449 0.978271" />
          <path d="M20.1462 23.0199L12.0003 14.874L3.85449 23.0199" />
        </>
      );
      break;

    case 'angles-expand':
      icon = (
        <>
          <path d="M20.1462 9.1241L12.0003 0.978271L3.85449 9.1241" />
          <path d="M20.1462 14.874L12.0003 23.0199L3.85449 14.874" />
        </>
      );
      break;

    case 'share':
      icon = (
        <>
          <g>
            <path d="M8.23747 10.2866C7.85161 9.51385 7.2161 8.89408 6.43395 8.52772C5.6518 8.16136 4.76884 8.06987 3.92819 8.26809C3.08754 8.46631 2.33847 8.94262 1.80238 9.61982C1.26629 10.297 0.974609 11.1354 0.974609 11.9991C0.974609 12.8628 1.26629 13.7012 1.80238 14.3784C2.33847 15.0556 3.08754 15.5319 3.92819 15.7301C4.76884 15.9283 5.6518 15.8369 6.43395 15.4705C7.2161 15.1041 7.85161 14.4844 8.23747 13.7116" />
            <path d="M19.187 8.64579C20.0237 8.64784 20.8382 8.37678 21.5068 7.87378C22.1753 7.37078 22.6615 6.66333 22.8914 5.85887C23.1213 5.0544 23.0824 4.19689 22.7806 3.41656C22.4788 2.63623 21.9305 1.97572 21.2191 1.53534C20.5077 1.09497 19.672 0.898802 18.839 0.976626C18.0059 1.05445 17.2211 1.40201 16.6035 1.96653C15.986 2.53105 15.5696 3.28168 15.4175 4.10442C15.2655 4.92715 15.3861 5.77704 15.761 6.525" />
            <path d="M15.7617 17.4741C15.3889 18.2202 15.2696 19.0676 15.4219 19.8877C15.5742 20.7078 15.9899 21.4558 16.6058 22.0182C17.2217 22.5807 18.0043 22.9269 18.8348 23.0044C19.6653 23.0818 20.4984 22.8863 21.2077 22.4474C21.917 22.0085 22.4638 21.3502 22.7652 20.5725C23.0665 19.7947 23.106 18.9399 22.8775 18.1377C22.6491 17.3355 22.1652 16.6297 21.4993 16.1273C20.8334 15.625 20.0219 15.3536 19.1878 15.3542" />
            <path d="M19.1872 8.64594C18.4756 8.64529 17.7782 8.44656 17.1731 8.072C16.568 7.69743 16.0791 7.16182 15.7612 6.52515L8.2373 10.2866C8.50573 10.8177 8.64558 11.4045 8.64558 11.9996C8.64558 12.5947 8.50573 13.1815 8.2373 13.7126L15.7612 17.4751C16.0791 16.8384 16.568 16.3028 17.1731 15.9282C17.7782 15.5537 18.4756 15.3549 19.1872 15.3543" />
          </g>
        </>
      );
      break;

    case 'check':
      icon = (
        <>
          <g>
            <path d="M23.0212 0.978271L7.20866 23.0199L0.979492 16.7908" />
          </g>
        </>
      );
      break;

    case 'angle-down-circle':
      icon = (
        <>
          <path d="M0.5 12a11.5 11.5 0 1 0 23 0 11.5 11.5 0 1 0 -23 0Z" />
          <path d="m5.5 8.545 6.1 7.955a0.5 0.5 0 0 0 0.794 0l6.1 -7.957" />
        </>
      );
      break;

    case 'angle-up-circle':
      icon = (
        <>
          <path d="M0.5 12a11.5 11.5 0 1 0 23 0 11.5 11.5 0 1 0 -23 0Z" />
          <path d="m18.5 15.5 -6.1 -7.99a0.5 0.5 0 0 0 -0.794 0L5.5 15.5" />
        </>
      );
      break;

    case 'download':
      icon = (
        <>
          <g>
            <path d="M22.5413 17.959V19.7923C22.5411 20.5214 22.2513 21.2206 21.7358 21.7362C21.2202 22.2517 20.521 22.5415 19.7919 22.5417H4.20747C3.47826 22.5417 2.77893 22.2521 2.26331 21.7364C1.74768 21.2208 1.45801 20.5215 1.45801 19.7923V17.959" />
            <path d="M12 17.8534V1.45825" />
            <path d="M18.7087 11.1453L12.0003 17.8536L5.29199 11.1453" />
          </g>
        </>
      );
      break;

    case 'envelope':
      icon = (
        <>
          <path d="M2.41699 4.8125H21.5837C21.5837 4.8125 23.0212 4.8125 23.0212 6.25V17.75C23.0212 17.75 23.0212 19.1875 21.5837 19.1875H2.41699C2.41699 19.1875 0.979492 19.1875 0.979492 17.75V6.25C0.979492 6.25 0.979492 4.8125 2.41699 4.8125Z" />
          <path d="M20.1462 8.64575L12.0003 13.9166L3.85449 8.64575" />
          <path d="M3.85449 15.8334L7.20866 13.9167" />
          <path d="M20.1462 15.8334L16.792 13.9167" />
        </>
      );
      break;

    case 'exclamation-circle':
      icon = (
        <>
          <path d="M12 15C12.414 15 12.75 14.664 12.75 14.25V6.75C12.75 6.336 12.414 6 12 6C11.586 6 11.25 6.336 11.25 6.75V14.25C11.25 14.664 11.586 15 12 15Z" />
          <path d="M12 18.75C12.6213 18.75 13.125 18.2463 13.125 17.625C13.125 17.0037 12.6213 16.5 12 16.5C11.3787 16.5 10.875 17.0037 10.875 17.625C10.875 18.2463 11.3787 18.75 12 18.75Z" />
          <path d="M12 0.75C9.01631 0.75 6.15483 1.93526 4.04505 4.04505C1.93526 6.15483 0.75 9.01631 0.75 12C0.75 14.9837 1.93526 17.8452 4.04505 19.955C6.15483 22.0647 9.01631 23.25 12 23.25C14.9837 23.25 17.8452 22.0647 19.955 19.955C22.0647 17.8452 23.25 14.9837 23.25 12C23.25 9.01631 22.0647 6.15483 19.955 4.04505C17.8452 1.93526 14.9837 0.75 12 0.75ZM5.10571 5.10571C6.93419 3.27723 9.41414 2.25 12 2.25C14.5859 2.25 17.0658 3.27723 18.8943 5.10571C20.7228 6.93419 21.75 9.41414 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75C9.41414 21.75 6.93419 20.7228 5.10571 18.8943C3.27723 17.0658 2.25 14.5859 2.25 12C2.25 9.41414 3.27723 6.93419 5.10571 5.10571Z" />
        </>
      );
      break;

    case 'triangle-down':
      icon = (
        <>
          <path d="M7 10L12 15L17 10L7 10Z" fill="currentColor" />
        </>
      );
      break;

    case 'chevron-right':
      icon = (
        <path d="M3.94385 15.3468L11.9785 8.23851C12.0123 8.20854 12.0393 8.17175 12.0579 8.13056C12.0764 8.08937 12.0859 8.04472 12.0859 7.99957C12.0859 7.95441 12.0764 7.90976 12.0579 7.86857C12.0393 7.82739 12.0123 7.79059 11.9785 7.76062L3.94385 0.652344" />
      );
      break;

    case 'chevron-left':
      icon = (
        <path d="M12.0562 0.653211L4.02149 7.76149C3.98771 7.79146 3.96067 7.82825 3.94215 7.86944C3.92363 7.91063 3.91406 7.95527 3.91406 8.00043C3.91406 8.04559 3.92363 8.09024 3.94215 8.13143C3.96067 8.17261 3.98771 8.20941 4.02149 8.23938L12.0562 15.3477" />
      );
      break;

    case 'phone':
      icon = (
        <path d="M9.08624 7.4004C9.5195 6.96637 9.76284 6.37817 9.76284 5.7649C9.76284 5.15164 9.5195 4.56343 9.08624 4.1294L6.63224 1.6784C6.19847 1.24465 5.61017 1.00098 4.99674 1.00098C4.38331 1.00098 3.79501 1.24465 3.36124 1.6784L2.01624 3.0244C1.45033 3.58941 1.09741 4.33286 1.01739 5.12852C0.937366 5.92418 1.13518 6.72302 1.57724 7.3894C5.53814 13.3513 10.6493 18.4625 16.6112 22.4234C17.2779 22.8649 18.0765 23.0625 18.8721 22.9829C19.6677 22.9032 20.4113 22.5512 20.9772 21.9864L22.3222 20.6404C22.5371 20.4257 22.7076 20.1707 22.8239 19.8901C22.9402 19.6095 23.0001 19.3087 23.0001 19.0049C23.0001 18.7011 22.9402 18.4003 22.8239 18.1197C22.7076 17.8391 22.5371 17.5841 22.3222 17.3694L19.8692 14.9164C19.6545 14.7015 19.3996 14.531 19.1189 14.4147C18.8383 14.2984 18.5375 14.2386 18.2337 14.2386C17.93 14.2386 17.6292 14.2984 17.3485 14.4147C17.0679 14.531 16.813 14.7015 16.5982 14.9164L15.7802 15.7344C13.0391 13.4786 10.5233 10.9621 8.26824 8.2204L9.08624 7.4004Z" />
      );
      break;

    case 'pin-location':
      icon = (
        <>
          <path d="M8.64551 7.20866C8.64551 7.64913 8.73227 8.0853 8.90083 8.49224C9.06939 8.89919 9.31646 9.26895 9.62792 9.58041C9.93938 9.89188 10.3091 10.1389 10.7161 10.3075C11.123 10.4761 11.5592 10.5628 11.9997 10.5628C12.4402 10.5628 12.8763 10.4761 13.2833 10.3075C13.6902 10.1389 14.06 9.89188 14.3714 9.58041C14.6829 9.26895 14.93 8.89919 15.0985 8.49224C15.2671 8.0853 15.3538 7.64913 15.3538 7.20866C15.3538 6.76818 15.2671 6.33202 15.0985 5.92508C14.93 5.51813 14.6829 5.14837 14.3714 4.83691C14.06 4.52544 13.6902 4.27838 13.2833 4.10981C12.8763 3.94125 12.4402 3.85449 11.9997 3.85449C11.5592 3.85449 11.123 3.94125 10.7161 4.10981C10.3091 4.27838 9.93938 4.52544 9.62792 4.83691C9.31646 5.14837 9.06939 5.51813 8.90083 5.92508C8.73227 6.33202 8.64551 6.76818 8.64551 7.20866Z" />
          <path d="M12 0.979492C13.7424 0.979746 15.4134 1.67209 16.6453 2.90424C17.8773 4.13639 18.5694 5.80743 18.5694 7.54983C18.5694 10.6309 13.8333 18.2688 12.4035 20.5036C12.3601 20.5713 12.3005 20.627 12.23 20.6655C12.1595 20.7041 12.0804 20.7243 12 20.7243C11.9197 20.7243 11.8406 20.7041 11.7701 20.6655C11.6996 20.627 11.6399 20.5713 11.5966 20.5036C10.1667 18.2688 5.43066 10.6328 5.43066 7.54983C5.43054 6.68704 5.60037 5.83268 5.93045 5.03554C6.26054 4.2384 6.74442 3.51408 7.35445 2.90396C7.96449 2.29383 8.68873 1.80985 9.48583 1.47965C10.2829 1.14945 11.1373 0.979492 12 0.979492Z" />
          <path d="M16.792 19.3789C20.4797 19.6894 23.0212 20.3459 23.0212 21.1039C23.0212 22.1629 18.0877 23.0206 12.0003 23.0206C5.91299 23.0206 0.979492 22.1629 0.979492 21.1039C0.979492 20.3468 3.5047 19.6923 7.16841 19.3789" />
        </>
      );
      break;

    case 'flag-exclamation':
      icon = (
        <>
          <g>
            <path d="M0.979492 23.0209V0.979248" />
            <path d="M0.979492 19.1874C2.84193 18.6007 4.77745 18.2781 6.72949 18.2291C9.60449 18.2291 13.917 20.1458 16.792 20.1458C18.7127 20.0547 20.6058 19.6521 22.3973 18.9536C22.5806 18.8853 22.7386 18.7626 22.8501 18.6019C22.9616 18.4412 23.0213 18.2503 23.0212 18.0547V4.75492C23.0211 4.60267 22.9849 4.45261 22.9153 4.31718C22.8457 4.18174 22.7449 4.06483 22.6212 3.97612C22.4974 3.88742 22.3544 3.82947 22.2038 3.80708C22.0532 3.78469 21.8994 3.7985 21.7552 3.84738C20.1596 4.41995 18.4859 4.74538 16.792 4.81242C13.917 4.81242 9.60449 2.89575 6.72949 2.89575C4.77745 2.94484 2.84194 3.26742 0.979492 3.85409V19.1874Z" />
            <path d="M12.4795 13.4375V7.6875" />
            <path d="M12.4794 15.3542H12.4746" />
            <path d="M12.4788 15.8334C12.4153 15.8334 12.3544 15.8082 12.3094 15.7632C12.2645 15.7183 12.2393 15.6574 12.2393 15.5938C12.2393 15.5303 12.2645 15.4694 12.3094 15.4244C12.3544 15.3795 12.4153 15.3542 12.4788 15.3542" />
            <path d="M12.4795 15.8334C12.543 15.8334 12.604 15.8082 12.6489 15.7632C12.6938 15.7183 12.7191 15.6574 12.7191 15.5938C12.7191 15.5303 12.6938 15.4694 12.6489 15.4244C12.604 15.3795 12.543 15.3542 12.4795 15.3542" />
          </g>
        </>
      );
      break;

    case 'zoom-in':
      icon = (
        <>
          <path d="M0.5 10.499a10 10 0 1 0 20 0 10 10 0 1 0 -20 0Z" />
          <path d="m23.5 23.499 -5.929 -5.929" />
          <path d="m5.5 10.499 10 0" />
          <path d="m10.5 5.499 0 10" />
        </>
      );
      break;

    case 'zoom-out':
      icon = (
        <>
          <path d="M0.5 10.499a10 10 0 1 0 20 0 10 10 0 1 0 -20 0Z" />
          <path d="m23.5 23.499 -5.929 -5.929" />
          <path d="m5.5 10.499 10 0" />
        </>
      );
      break;

    case 'factory-building':
      icon = (
        <>
          <path d="m20.25 19.5 -1.5 0" />
          <path d="m20.25 16 -1.5 0" />
          <path d="m15.25 19.5 -1.5 0" />
          <path d="m10.25 19.5 -1.5 0" />
          <path d="m5.25 19.5 -1.5 0" />
          <path d="M15.25 16v-3a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v10H0.75v-6a1 1 0 0 1 1 -1Z" />
          <path d="m11.5 16 -3 0 0 -9 3 0Z" />
          <path d="m21.25 12 -3 0 0 -3 3 0Z" />
          <path d="m5.25 16 -3 0 0 -7 3 0Z" />
          <path d="M20.75 1a2.489 2.489 0 0 0 -2.158 1.259 1.5 1.5 0 1 0 0 2.482A2.5 2.5 0 1 0 20.75 1Z" />
          <path d="M11.25 3.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0" />
        </>
      );
      break;

    // expand 5
    case 'expand':
      icon = (
        <>
          <path d="M7.5 6.499h9s1 0 1 1v9s0 1 -1 1h-9s-1 0 -1 -1v-9s0 -1 1 -1" />
          <path d="m19.5 4.499 4 -4" />
          <path d="m0.5 23.499 4 -4" />
          <path d="m5.5 23.499 -5 0 0 -5" />
          <path d="m23.5 5.499 0 -5 -5 0" />
          <path d="m19.5 19.497 4 4.002" />
          <path d="m0.5 0.499 4 4" />
          <path d="m0.5 5.499 0 -5 5 0" />
          <path d="m18.5 23.499 5 0 0 -5" />
        </>
      );
      break;

    case 'cookie':
      icon = (
        <>
          <path d="M20 6.88003c-0.7394 -0.00061 -1.4639 -0.20738 -2.0923 -0.59707 -0.6283 -0.38969 -1.1356 -0.94686 -1.4647 -1.60893 -0.0296 -0.05891 -0.0705 -0.11139 -0.1205 -0.15442 -0.0499 -0.04302 -0.1079 -0.07575 -0.1705 -0.09629 -0.0627 -0.02054 -0.1288 -0.02849 -0.1945 -0.02339 -0.0657 0.0051 -0.1298 0.02314 -0.1885 0.0531 -0.5474 0.2807 -1.1538 0.42707 -1.769 0.427 -1.0609 0 -2.0783 -0.42143 -2.8284 -1.17158C10.4214 2.95831 10 1.94089 10 0.880027c0.0001 -0.038679 -0.0085 -0.076886 -0.02507 -0.111837C9.95836 0.733239 9.9342 0.702426 9.90422 0.677995 9.87423 0.653564 9.83917 0.636132 9.80159 0.626969 9.76401 0.617806 9.72486 0.617142 9.687 0.625027 7.55563 1.06265 5.59215 2.09595 4.02459 3.60492 2.45703 5.11389 1.34971 7.03661 0.83125 9.14976c-0.518463 2.11314 -0.426801 4.33004 0.26434 6.39314 0.69114 2.0632 1.95341 3.8879 3.64019 5.2623 1.68678 1.3744 3.72886 2.2421 5.88912 2.5023 2.1602 0.2602 4.3499 -0.0978 6.3148 -1.0324 1.9648 -0.9346 3.6243 -2.4075 4.7855 -4.2475 1.1613 -1.84 1.7767 -3.9717 1.7748 -6.1476 0.0003 -1.7816 -0.4186 -3.53828 -1.223 -5.12797 -0.0581 -0.11544 -0.1587 -0.20387 -0.2807 -0.24675 -0.1219 -0.04288 -0.2557 -0.03688 -0.3733 0.01675 -0.5088 0.23525 -1.0625 0.35739 -1.623 0.358v0Z" />
          <path d="M8 10.88c1.10457 0 2 -0.89543 2 -2 0 -1.10456 -0.89543 -2 -2 -2s-2 0.89544 -2 2c0 1.10457 0.89543 2 2 2Z" />
          <path d="M15 14.88c0.5523 0 1 -0.4477 1 -1s-0.4477 -1 -1 -1 -1 0.4477 -1 1 0.4477 1 1 1Z" />
          <path d="M9 18.88c1.1046 0 2 -0.8954 2 -2s-0.8954 -2 -2 -2c-1.10457 0 -2 0.8954 -2 2s0.89543 2 2 2Z" />
          <path d="M4 13.13c-0.13807 0 -0.25 -0.1119 -0.25 -0.25s0.11193 -0.25 0.25 -0.25" />
          <path d="M4 13.13c0.13807 0 0.25 -0.1119 0.25 -0.25s-0.11193 -0.25 -0.25 -0.25" />
          <path d="M16 10.13c-0.1381 0 -0.25 -0.1119 -0.25 -0.25 0 -0.13807 0.1119 -0.25 0.25 -0.25" />
          <path d="M16 10.13c0.1381 0 0.25 -0.1119 0.25 -0.25 0 -0.13807 -0.1119 -0.25 -0.25 -0.25" />
          <g>
            <path d="M15 19.13c-0.1381 0 -0.25 -0.1119 -0.25 -0.25s0.1119 -0.25 0.25 -0.25" />
            <path d="M15 19.13c0.1381 0 0.25 -0.1119 0.25 -0.25s-0.1119 -0.25 -0.25 -0.25" />
          </g>
        </>
      );
      break;

    case 'question-circle':
      icon = (
        <>
          <path d="M12 23.001c6.0751 0 11 -4.9249 11 -11 0 -6.07516 -4.9249 -11.00002 -11 -11.00002 -6.07513 0 -11 4.92486 -11 11.00002 0 6.0751 4.92487 11 11 11Z" />
          <path d="M9 10.005c0.00008 -0.54081 0.14637 -1.07158 0.4234 -1.5361 0.27702 -0.46452 0.6745 -0.84551 1.1503 -1.10265 0.4758 -0.25714 1.0123 -0.38087 1.5526 -0.35809 0.5404 0.02277 1.0646 0.19121 1.5171 0.48748 0.4524 0.29627 0.8164 0.70935 1.0534 1.19554 0.2369 0.48618 0.338 1.02737 0.2926 1.56632 -0.0454 0.5389 -0.2357 1.0556 -0.5507 1.4952 -0.315 0.4397 -0.743 0.786 -1.2387 1.0023 -0.3567 0.1557 -0.6602 0.412 -0.8733 0.7376 -0.2132 0.3256 -0.3267 0.7063 -0.3267 1.0954V15" />
          <path d="M12.0036 17.5c-0.1381 0 -0.25 -0.1119 -0.25 -0.25s0.1119 -0.25 0.25 -0.25" />
          <path d="M12.0036 17.5c0.1381 0 0.25 -0.1119 0.25 -0.25s-0.1119 -0.25 -0.25 -0.25" />
        </>
      );
      break;

    case 'dashboard':
      icon = (
        <>
          <path d="M1.5 16.5h8s1 0 1 1v5s0 1 -1 1h-8s-1 0 -1 -1v-5s0 -1 1 -1" fill="none" />
          <path
            d="m22.5 23.5 -8 0s-1 0 -1 -1l0 -11s0 -1 1 -1l8 0s1 0 1 1l0 11s0 1 -1 1"
            fill="none"
          />
          <path d="m22.5 7.5 -8 0s-1 0 -1 -1l0 -5s0 -1 1 -1l8 0s1 0 1 1l0 5s0 1 -1 1" fill="none" />
          <path d="M1.5 0.5h8s1 0 1 1v11s0 1 -1 1h-8s-1 0 -1 -1v-11s0 -1 1 -1" fill="none" />
        </>
      );
      break;

    case 'add':
      icon = (
        <>
          <path d="m12 1 0 22" />
          <path d="M23 12 1 12" />
        </>
      );
      break;

    case 'cog':
      icon = (
        <>
          <path d="M19.922 7.213a1.874 1.874 0 0 0 1.065 2.571l1.265 0.45a1.875 1.875 0 0 1 0 3.534l-1.265 0.45a1.874 1.874 0 0 0 -1.065 2.571L20.5 18a1.875 1.875 0 0 1 -2.5 2.5l-1.213 -0.576a1.874 1.874 0 0 0 -2.571 1.065l-0.45 1.265a1.875 1.875 0 0 1 -3.533 0l-0.45 -1.265a1.875 1.875 0 0 0 -2.572 -1.065L6 20.5A1.874 1.874 0 0 1 3.5 18l0.576 -1.213a1.874 1.874 0 0 0 -1.065 -2.571l-1.265 -0.45a1.875 1.875 0 0 1 0 -3.534l1.265 -0.45a1.874 1.874 0 0 0 1.065 -2.569L3.5 6A1.874 1.874 0 0 1 6 3.5l1.213 0.576a1.875 1.875 0 0 0 2.57 -1.063l0.45 -1.265a1.875 1.875 0 0 1 3.533 0l0.45 1.265a1.874 1.874 0 0 0 2.571 1.065L18 3.5A1.875 1.875 0 0 1 20.5 6Z" />
          <path d="M7.499 12.001a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0 -9 0Z" />
        </>
      );
      break;

    case 'analytic-board-bars':
      icon = (
        <>
          <path d="M1.504 3.5h21s1 0 1 1v15s0 1 -1 1h-21s-1 0 -1 -1v-15s0 -1 1 -1" />
          <path d="m4.504 16.5 0 -2" />
          <path d="m7.504 16.5 0 -5" />
          <path d="m10.504 16.5 0 -3" />
          <path d="m19.504 16.5 0 -7" />
          <path d="m16.504 16.5 0 -4.5" />
          <path d="m13.504 16.5 0 -9" />
        </>
      );
      break;

    case 'subscription':
      icon = (
        <>
          <path d="M9.75 14.7388H4.125C4.02554 14.7388 3.93016 14.6993 3.85984 14.6289C3.78951 14.5586 3.75 14.4632 3.75 14.3638V1.61377C3.75 1.51431 3.78951 1.41893 3.85984 1.3486C3.93016 1.27828 4.02554 1.23877 4.125 1.23877H13.125C13.2245 1.23877 13.3198 1.27828 13.3902 1.3486C13.4605 1.41893 13.5 1.51431 13.5 1.61377V10.9888" />
          <path d="M3.75 13.2388H1.125C1.02554 13.2388 0.930161 13.1993 0.859835 13.1289C0.789509 13.0586 0.75 12.9632 0.75 12.8638V3.11377C0.75 3.01431 0.789509 2.91893 0.859835 2.8486C0.930161 2.77828 1.02554 2.73877 1.125 2.73877H3.75" />
          <path d="M13.5 2.73877H16.125C16.2245 2.73877 16.3198 2.77828 16.3902 2.8486C16.4605 2.91893 16.5 3.01431 16.5 3.11377V12.1138" />
          <path d="M5.625 4.23877H11.625" />
          <path d="M5.625 6.48877H11.625" />
          <path d="M5.625 8.73877H9.375" />
          <path d="M17.2496 18.4888L14.4971 15.7363" />
          <path d="M12.7501 17.7989C12.7809 17.8865 12.8372 17.9629 12.9118 18.0183C12.9864 18.0736 13.0758 18.1054 13.1686 18.1094C13.2615 18.1135 13.3533 18.0896 13.4325 18.0409C13.5116 17.9923 13.5743 17.921 13.6126 17.8364L14.5126 15.7364L16.6126 14.8364C16.7001 14.7948 16.7725 14.7269 16.8195 14.6422C16.8666 14.5574 16.886 14.4601 16.8751 14.3639C16.872 14.27 16.8402 14.1793 16.7838 14.1042C16.7275 14.029 16.6494 13.9731 16.5601 13.9439L11.7001 12.3539C11.6187 12.3277 11.5316 12.3245 11.4485 12.3446C11.3654 12.3647 11.2894 12.4073 11.229 12.4677C11.1685 12.5282 11.1259 12.6042 11.1058 12.6873C11.0857 12.7704 11.0889 12.8574 11.1151 12.9389L12.7501 17.7989Z" />
        </>
      );
      break;

    case 'paper-write':
      icon = (
        <>
          <path d="M18.5 19v3.5a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-18a1 1 0 0 1 1 -1h3" />
          <path d="M14.5 3.5h3a1 1 0 0 1 1 1V8" />
          <path d="M8 1.999a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0Z" />
          <path d="M10.915 2.5H13.5a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h2.585" />
          <path d="m3.5 9.499 12 0" />
          <path d="m3.5 13.499 9.466 0" />
          <path d="m3.5 17.499 7.983 0" />
          <path d="m17 17.5 -3.5 1 1 -3.5 5.982 -5.982a1.768 1.768 0 0 1 2.5 2.5Z" />
        </>
      );
      break;

    case 'remove-circle':
      icon = (
        <>
          <path d="M0.999 12a11 11 0 1 0 22 0 11 11 0 1 0 -22 0Z" />
          <path d="m16.241 7.758 -8.485 8.485" />
          <path d="M16.241 16.243 7.756 7.758" />
        </>
      );
      break;

    case 'house':
      icon = (
        <>
          <path d="M3.67 9.55V21.8C3.67 22.3412 4.1088 22.78 4.65 22.78H19.35C19.8912 22.78 20.33 22.3412 20.33 21.8V9.55" />
          <path d="M0.73 12.49L12 1.22L23.27 12.49" />
          <path d="M10.04 14.94H13.96C14.5012 14.94 14.94 15.3788 14.94 15.92V22.78H14.94H9.06H9.06V15.92C9.06 15.3788 9.4988 14.94 10.04 14.94Z" />
        </>
      );
      break;

    case 'plus-circle':
      icon = (
        <>
          <path d="M12.05 23.2998c6.2132 0 11.25 -5.0369 11.25 -11.2501C23.3 5.83655 18.2632 0.799805 12.05 0.799805 5.83678 0.799805 0.800003 5.83655 0.800003 12.0497c0 6.2132 5.036777 11.2501 11.249997 11.2501Z" />
          <path d="M12 6.3999V17.7" />
          <path d="M6.40001 12H17.6" />
        </>
      );
      break;

    case 'show-hat-magician':
      icon = (
        <>
          <g>
            <path d="m0.5 1.5 2 0" />
            <path d="m1.5 0.5 0 2" />
          </g>
          <g>
            <path d="m14.5 8.5 2 0" />
            <path d="m15.5 7.5 0 2" />
          </g>
          <g>
            <path d="m10.5 2 2 0" />
            <path d="m11.5 1 0 2" />
          </g>
          <path d="M6.5 2.5 5.17 5.17 2.5 6.5l2.67 1.33L6.5 10.5l1.33 -2.67L10.5 6.5 7.83 5.17 6.5 2.5" />
          <g>
            <path d="M15.5 20.83a2.43 2.43 0 0 1 -2.08 2.67H5.58a2.43 2.43 0 0 1 -2.08 -2.67V12.5h12Z" />
            <path d="m0.5 12.5 18 0" />
            <path d="m3.5 15.5 12 0" />
          </g>
          <path d="M23.5 0.5 12 5.5" />
        </>
      );
      break;

    case 'list-to-do':
      icon = (
        <>
          <path d="M21.207 4.5a1 1 0 0 1 0.293 0.707V22.5a1 1 0 0 1 -1 1h-17a1 1 0 0 1 -1 -1v-21a1 1 0 0 1 1 -1h13.293a1 1 0 0 1 0.707 0.293Z" />
          <path d="m6 7.771 1.083 1.082a0.5 0.5 0 0 0 0.76 -0.062L10 5.771" />
          <path d="m13.5 7.5 4 0" />
          <path d="m6 12.771 1.083 1.082a0.5 0.5 0 0 0 0.76 -0.062L10 10.771" />
          <path d="m13.5 12.5 4 0" />
          <path d="m6 17.771 1.083 1.082a0.5 0.5 0 0 0 0.76 -0.062L10 15.771" />
          <path d="m13.5 17.5 4 0" />
        </>
      );
      break;

    case 'messages-bubble-square-information':
      icon = (
        <>
          <path d="M23.5 17.5c0 0.2652 -0.1054 0.5196 -0.2929 0.7071s-0.4419 0.2929 -0.7071 0.2929h-11l-4 4v-4h-6c-0.26522 0 -0.51957 -0.1054 -0.707107 -0.2929C0.605357 18.0196 0.5 17.7652 0.5 17.5v-15c0 -0.26522 0.105357 -0.51957 0.292893 -0.70711C0.98043 1.60536 1.23478 1.5 1.5 1.5h21c0.2652 0 0.5196 0.10536 0.7071 0.29289 0.1875 0.18754 0.2929 0.44189 0.2929 0.70711v15Z" />
          <path d="M12.5 14.5v-6c0 -0.26522 -0.1054 -0.51957 -0.2929 -0.70711C12.0196 7.60536 11.7652 7.5 11.5 7.5h-1" />
          <path d="M10.5 14.5h4" />
          <path d="M12.25 5.5c-0.1381 0 -0.25 -0.11193 -0.25 -0.25s0.1119 -0.25 0.25 -0.25" />
          <path d="M12.25 5.5c0.1381 0 0.25 -0.11193 0.25 -0.25S12.3881 5 12.25 5" />
        </>
      );
      break;

    case 'navigation-menu-vertical':
      icon = (
        <>
          <path d="M8.75 3.25a3.25 3.25 0 1 0 6.5 0 3.25 3.25 0 1 0 -6.5 0" />
          <path d="M8.75 12a3.25 3.25 0 1 0 6.5 0 3.25 3.25 0 1 0 -6.5 0" />
          <path d="M8.75 20.75a3.25 3.25 0 1 0 6.5 0 3.25 3.25 0 1 0 -6.5 0" />
        </>
      );
      break;

    // case 'remove-circle':
    //   icon = (
    //     <>
    //       <path d="m7 16.999 10 -10" />
    //       <path d="m17 16.999 -10 -10" />
    //       <path d="M0.5 11.999a11.5 11.5 0 1 0 23 0 11.5 11.5 0 1 0 -23 0Z" />
    //     </>
    //   );
    //   break;

    case 'link':
      icon = (
        <>
          <path
            d="M13.416666666666668 11.4977C13.416666666666668 9.109820833333334 11.196208333333333 6.708333333333334 8.4880925 6.708333333333334c-0.3207829166666667 0 -1.3781791666666667 0.00000958333333333334 -1.6428516666666668 0.00000958333333333334C4.1232675 6.708342916666666 1.9166666666666667 8.852623333333334 1.9166666666666667 11.4977c0 2.2793 1.63849125 4.186766666666667 3.8333333333333335 4.670725 0.35224500000000003 0.07772083333333334 0.7188074999999999 0.11864166666666666 1.0952408333333334 0.11864166666666666"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M9.583333333333334 11.4977c0 2.3878791666666666 2.2204583333333336 4.789366666666667 4.9286125 4.789366666666667l1.642775 0c2.72205 0 4.9286125 -2.144270833333333 4.9286125 -4.789366666666667 0 -2.2793 -1.6384625000000002 -4.186728333333334 -3.8333333333333335 -4.670696250000001 -0.35228333333333334 -0.07767291666666666 -0.7188458333333334 -0.11868000000000001 -1.0952791666666668 -0.11868000000000001"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </>
      );
      break;

    case 'menu-burger':
      icon = (
        <>
          <path
            d="M1.0969083333333334 3.4086958333333337h20.806183333333333"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M1.0969083333333334 11.5h20.806183333333333"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M1.0969083333333334 19.591304166666667h20.806183333333333"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </>
      );
      break;

    case 'user':
      icon = (
        <>
          <path
            d="M11.5 1.0163125C5.7100375 1.0163125 1.0163125 5.7100375 1.0163125 11.5c0 5.7899625 4.693725000000001 10.4836875 10.4836875 10.4836875 5.7899625 0 10.4836875 -4.693725000000001 10.4836875 -10.4836875 0 -5.7899625 -4.693725000000001 -10.4836875 -10.4836875 -10.4836875Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M3.3971 18.152654166666668S5.733995833333333 15.169266666666667 11.5 15.169266666666667s8.102995833333333 2.9833875000000005 8.102995833333333 2.9833875000000005"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M11.5 11.5c1.7370750000000001 0 3.1451541666666665 -1.4080791666666668 3.1451541666666665 -3.1451541666666665 0 -1.7369791666666667 -1.4080791666666668 -3.1450583333333335 -3.1451541666666665 -3.1450583333333335S8.354845833333334 6.617866666666667 8.354845833333334 8.354845833333334C8.354845833333334 10.091920833333333 9.762925000000001 11.5 11.5 11.5Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </>
      );
      break;

    case 'login':
      icon = (
        <>
          <path
            d="M22.586 7.5a11.5 11.5 0 1 0 0 9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />

          <path d="m11.5 8 -4 4 4 4" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />

          <path d="m7.5 12 16 0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </>
      );
      break;

    default:
      break;
  }

  if (!icon) {
    return null;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox={`0 0 24 24`}
      className={cx('icon', `icon-${i}`, iconx({ size }), className)}
      ref={ref}
      {...restProps}
    >
      {icon}
    </svg>
  );
});
Icon.displayName = 'Icon';

export { ICONS, Icon };
export type { IconProps, IconType };
